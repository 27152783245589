<template>
  <div v-if="pagina">
    <div v-if="!pending" id="banner">
      <CustomBanner :banners="banners" :class="props.class" />
    </div>
  </div>
  <div v-else-if="imagem">
    <CustomBanner :imagem="imagem" :class="props.class">
      <h1>{{ titulo }}</h1>
    </CustomBanner>
  </div>
</template>

<script setup>
const { locale } = useI18n();
const props = defineProps({
  class: String,
  pagina: String,
  imagem: String,
  titulo: String,
});
const { data: banners, pending } = await useFetch(
  `/api/site/banners/${props.pagina}`,
  { headers: { "Content-Language": locale } }
);
</script>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Banners",
};
</script>
